import AppCard from 'components/widgets/app-card';

import {getInputPlaceHolder, getSelectPlaceHolder} from 'utils/message-helper';
import {Button} from 'antd';
import LocationHelper from 'utils/location-helper';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import AppSelect from 'components/controles/select';
import AppInput from 'components/controles/input';
import {yupResolver} from '@hookform/resolvers/yup';
import {primaryAddressSchema} from 'schemas/primary-address.schema';

const PrimaryAddressForm = ({onContinue, details, setState}) => {
  const methods = useForm({
    defaultValues: details,
    resolver: yupResolver(primaryAddressSchema),
  });
  const {handleSubmit, watch, setValue} = methods;

  const country = watch('country');

  useEffect(() => {
    if (country !== 'united_states') {
      setValue('us_state', undefined);
    }
  }, [country]);

  const onSubmit = (formData) => {
    setState({primary_address: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AppSelect
            isRequired
            showSearch
            name='country'
            label='Country'
            placeholder={getSelectPlaceHolder('country')}
            options={LocationHelper.getCountries()}
          />

          <AppInput
            isRequired
            name='address_line_1'
            label='Address Line 1'
            placeholder={getInputPlaceHolder('address line 1')}
          />

          <AppInput
            name='address_line_2'
            label='Address Line 2'
            placeholder={getInputPlaceHolder('address line 2')}
          />
          <AppInput
            isRequired
            name='city'
            label='City'
            placeholder={getInputPlaceHolder('city')}
          />

          <AppSelect
            name='us_state'
            label='State / Province'
            showSearch
            isRequired={country === 'united_states'}
            placeholder={getSelectPlaceHolder('state/province')}
            options={LocationHelper.getUsStates()}
            disabled={country !== 'united_states'}
          />

          <AppInput
            isRequired
            name='postal_code'
            label='Postal / Zip Code'
            placeholder={getInputPlaceHolder('postal/zip code')}
          />

          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </form>
      </FormProvider>
    </AppCard>
  );
};

PrimaryAddressForm.propTypes = {
  details: PropTypes.object,
  setState: PropTypes.func,
  onContinue: PropTypes.func,
};

export default PrimaryAddressForm;
