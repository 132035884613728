import AppCard from 'components/widgets/app-card';
import {Typography} from 'antd';
import {getPhoneNumber} from 'utils/data-format-helper';
import PropTypes from 'prop-types';
import React from 'react';

const ContactDetailsView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>First Name</Typography.Text>
        <Typography.Paragraph className='text-sm'>
          {details?.first_name}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>Last Name</Typography.Text>
        <Typography.Paragraph className='text-sm'>
          {details?.last_name}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>Title</Typography.Text>
        <Typography.Paragraph className='text-sm'>
          {details?.title}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>
          Email Address
        </Typography.Text>
        <Typography.Paragraph className='text-sm'>
          {details?.email_address || details?.email}
        </Typography.Paragraph>
      </div>
      {!!details?.phone_number && (
        <div className={'mb-5'}>
          <Typography.Text className='font-semibold'>
            Phone Number
          </Typography.Text>
          <Typography.Paragraph className='text-sm'>
            {getPhoneNumber(details?.phone_number)}
          </Typography.Paragraph>
        </div>
      )}
    </AppCard>
  );
};

ContactDetailsView.propTypes = {
  details: PropTypes.object,
  isClient: PropTypes.bool,
};

export default ContactDetailsView;
