import {useMutation} from 'react-query';
import {instance} from '../api/axios';
import axios from 'axios';

export const useUploadFile = () => {
  return useMutation(async (file) => {
    const response = await instance.post('/job_applications/upload_resume', {
      content_type: file.type,
      content_length: file.size,
    });
    await axios.put(response.data.upload_url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    return response.data;
  });
};
