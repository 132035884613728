import {Button, Space} from 'antd';
import PropTypes from 'prop-types';
import {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate} from 'react-router-dom';
import AppCard from '../../widgets/app-card';

const KEY = '6LfXCngqAAAAALackQnxk3UC8QFx6JYS_dlkIaU6';
const CreateAccount = ({onFinish, isLoading}) => {
  const navigation = useNavigate();
  const onCancelCreate = () => {
    navigation('/');
  };
  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  return (
    <AppCard>
      <ReCAPTCHA sitekey={KEY} onChange={handleRecaptchaChange} />
      <Space size={'large'} className='mt-5'>
        <Button
          type='primary'
          onClick={onFinish}
          loading={isLoading}
          disabled={isLoading || !isVerified}>
          Apply
        </Button>
        <Button onClick={onCancelCreate} disabled={isLoading}>
          Cancel
        </Button>
      </Space>
    </AppCard>
  );
};

CreateAccount.propTypes = {
  onFinish: PropTypes.func,
};

export default CreateAccount;
