import React from 'react';
import AppCard from '../../widgets/app-card';
import {Typography} from 'antd';
import moment from 'moment';
import {EDUCATIONS} from '../../../constants/educations';

const ProfessionalExperienceView = ({details}) => {
  console.log(details);
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5 flex flex-col gap-2'}>
        <Typography.Text className='font-semibold'>Resume / CV</Typography.Text>
        <Typography.Paragraph>{details?.file?.file?.name}</Typography.Paragraph>
      </div>
      {details?.linkedin && (
        <div className={'mb-5'}>
          <Typography.Text className='font-semibold'>
            LinkedIn Profile
          </Typography.Text>
          <Typography.Paragraph>{details?.linkedin}</Typography.Paragraph>
        </div>
      )}
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>
          Years of Work Experience
        </Typography.Text>
        <Typography.Paragraph>
          {details?.years_of_experience}
        </Typography.Paragraph>
      </div>
      {details?.available_from && (
        <div className={'mb-5'}>
          <Typography.Text className='font-semibold'>
            Available From
          </Typography.Text>
          <Typography.Paragraph>
            {moment(details?.available_from).format('ll')}
          </Typography.Paragraph>
        </div>
      )}
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>
          Education Degree Level
        </Typography.Text>
        <Typography.Paragraph>
          {EDUCATIONS[details?.education]}
        </Typography.Paragraph>
      </div>
      {details?.cover_letter && (
        <div className={'mb-5'}>
          <Typography.Text className='font-semibold'>
            Cover Letter
          </Typography.Text>
          <Typography.Paragraph>
            <div dangerouslySetInnerHTML={{__html: details?.cover_letter}} />
          </Typography.Paragraph>
        </div>
      )}
    </AppCard>
  );
};

export default ProfessionalExperienceView;
