import {useMemo} from 'react';
import {formatBillingRateRage} from '../utils/data-format-helper';

export const useSalary = (data) => {

  const isBill = useMemo(() => {
    if (data?.type === 'contract') {
      return data?.contract_type !== 'c2c';
    }
    return data?.type === 'temporary';
  }, [data]);

  const salary = useMemo(() => {
    if (data) {
      const {
        billing_rate,
        billing_rate_min,
        billing_rate_max,
        base_salary,
        base_salary_min,
        base_salary_max,
      } = data;
      if (isBill) {
        return formatBillingRateRage(
          billing_rate,
          billing_rate_min,
          billing_rate_max,
          false,
        );
      }
      return formatBillingRateRage(
        base_salary,
        base_salary_min,
        base_salary_max,
        false,
      );
    }
    return 0;
  }, [data]);

  return {
    salary,
    isBill,
  };
};
