export const getInputPlaceHolder = (content) => {
  return `Enter ${content}`;
};

export const getSelectPlaceHolder = (content) => {
  return `Select ${content}`;
};

export const getRequiredMessage = (fieldName) => {
  return `${fieldName} is required field`;
};
