import React from 'react';
import AppCard from '../../widgets/app-card';
import {FormProvider, useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import UploadFile from '../../controles/upload-file';
import AppInput from '../../controles/input';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '../../../utils/message-helper';
import AppDatePicker from '../../controles/date-picker';
import AppSelect from '../../controles/select';
import {getOptionsFromObject} from '../../../utils/get-options-from-obj';
import {EDUCATION} from '../../../constants/education';
import AppEditor from '../../controles/editor';
import {Button} from 'antd';
import {ProfessionalExperienceSchema} from '../../../schemas/professional-experience.schema';
import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment';

const ProfessionalExperienceForm = ({details, setState, onContinue}) => {
  const methods = useForm({
    shouldFocusError: false,
    defaultValues: {
      ...details,
      available_from: details?.available_from
        ? moment(details?.available_from)
        : undefined,
    },
    mode: 'all',
    resolver: yupResolver(ProfessionalExperienceSchema),
  });
  const {handleSubmit} = methods;

  const onSubmit = (formData) => {
    setState({professional_experience: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UploadFile isRequired label='Resume / CV'>
            Upload Resume / CV
          </UploadFile>
          <AppInput
            name='linkedin'
            label='LinkedIn Profile'
            placeholder={getInputPlaceHolder('LinkedIn profile')}
          />
          <AppInput
            type='number'
            min={0}
            name='years_of_experience'
            label='Years of Work Experience'
            isRequired
            placeholder={getInputPlaceHolder('years of experience')}
          />
          <AppDatePicker
            name='available_from'
            label='Available From'
            format='MM-DD-YYYY'
            placeholder='MM-DD-YYYY'
          />
          <AppSelect
            name='education'
            label='Education Degree Level'
            placeholder={getSelectPlaceHolder('level')}
            isRequired
            options={getOptionsFromObject(EDUCATION)}
          />
          <AppEditor label='Cover Letter' name='cover_letter' />
          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </form>
      </FormProvider>
    </AppCard>
  );
};

ProfessionalExperienceForm.propTypes = {
  details: PropTypes.object,
  setState: PropTypes.func,
  onContinue: PropTypes.func,
};

export default ProfessionalExperienceForm;
