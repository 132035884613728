import {COUNTRIES} from '../constants/countries';
import {US_STATES} from '../constants/us-states';

export const getLocation = (object) => {
  if (!object) {
    return '';
  }

  const initCountry = object.country || object.job_country;

  const parsedCountry = Array.isArray(initCountry) ? initCountry[0] : initCountry;

  const isUSA =
    parsedCountry === 'united_states' ||
    parsedCountry === COUNTRIES['united_states'];

  const country = initCountry && (COUNTRIES[parsedCountry] || parsedCountry);

  const state =
    object.us_state && (US_STATES[object.us_state] || object.us_state);

  const city = object?.city;

  const isComma = !!city && (!!country || !!state);

  return `${city || ''}${isComma ? ',' : ''} ${
    isUSA ? state || '' : country || ''
  }`;
};
