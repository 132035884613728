import {date, mixed, number, object, string} from 'yup';
import {URL} from 'utils/regx';

export const ProfessionalExperienceSchema = object({
  file: mixed().required('File is required'),
  linkedin: string().when({
    is: (value) => !!value?.length,
    then: (schema) => schema.matches(URL, 'Enter correct url!'),
    otherwise: (schema) => schema,
  }),
  years_of_experience: number().required('Field is required'),
  available_from: date(),
  education: string().required('Field is required'),
  cover_letter: string(),
});
