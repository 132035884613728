import {formatPhoneNumberIntl} from 'react-phone-number-input';
import accounting from 'accounting';
export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  return formatPhoneNumberIntl(
    phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`,
  );
};

export const formatBillingRateRage = (
  billingRate,
  billingMin,
  billingMax,
  isCurrency = true,
) => {
  if (!billingRate && !billingMin && !billingMax) {
    return 'N/A';
  }
  if (billingMin && billingMax) {
    return `${accounting.formatMoney(
      Math.round(Number(billingMin)),
      '',
      0,
    )} - ${accounting.formatMoney(Math.round(Number(billingMax)), '', 0)} ${
      isCurrency ? 'USD' : ''
    }`;
  }

  if (billingRate) {
    return `${accounting.formatMoney(Number(billingRate), '', 0)} ${
      isCurrency ? 'USD' : ''
    }`;
  }
  return `${accounting.formatMoney(Number(billingMin || billingMax), '', 0)} ${
    isCurrency ? 'USD' : ''
  }`;
};
