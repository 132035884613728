import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'antd';
import {WORKSITES} from '../../constants/work_sites';
import cn from 'clsx';
import {useNavigate, useSearchParams} from 'react-router-dom';

const JobItem = ({icon, title, location, company, workModel, isActive, id}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    const params = new URLSearchParams(searchParams);
    navigate(`/${id}?${params.toString()}`);
  };

  return (
    <div
      className={cn(
        'p-4 flex border-b',
        'cursor-pointer',
        isActive && 'bg-primaryLight',
      )}
      onClick={handleClick}>
      <img className='h-10 w-10 mr-5' src={icon} alt='company-logo' />
      <div className='flex flex-col'>
        <Typography.Title level={5} className='!text-primary'>
          {title}
        </Typography.Title>
        <Typography.Text>{company}</Typography.Text>
        <Typography.Text className='text-gray-300'>
          {location} ({WORKSITES[workModel]})
        </Typography.Text>
      </div>
    </div>
  );
};

JobItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  workModel: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
};
export default JobItem;
