import React, {useEffect, useMemo, useState} from 'react';
import Sidebar from '../components/jobs/sidebar';
import Content from '../components/jobs/content';
import Search from '../components/widgets/search';
import {Typography} from 'antd';
import {useSearchParams} from 'react-router-dom';
import {useGetJobs, useSearchJobs} from '../hooks/use-get-jobs';
import Navigation from '../components/widgets/navigation';

const getData = (response) => [response?.data, response?.total_count || 0];
const Home = () => {
  const [searchParams] = useSearchParams();
  const q_text = searchParams.get('q_text');
  const q_location = searchParams.get('q_location');

  const [page, setPage] = useState(1);
  const handleChangePage = (p) => {
    setPage(p);
  };
  const {data: resultsData, isLoading} = useGetJobs({
    page: page - 1,
  });

  const {data: searchData, isLoading: isSearchLoading} = useSearchJobs({
    page: page - 1,
    q_location,
    q_text,
  });

  const [results, resultsCount] = getData(resultsData);
  const [searchResults, searchResultsCount] = getData(searchData);

  const isShowNoResults =
    (!!q_text || !!q_location) && !isSearchLoading && !searchResultsCount;

  const isSearchResult = (!!q_location || !!q_text) && searchResultsCount;

  const count = isSearchResult ? searchResultsCount : resultsCount;
  const data = isSearchResult ? searchResults : results;
  const isNoResults = !resultsCount && !isLoading;

  useEffect(() => {
    if (q_text || q_location) {
      setPage(1);
    }
  }, [q_text, q_location]);

  const title = useMemo(() => {
    if (isShowNoResults) {
      return 'Jobs you may be interested in';
    }
    if (isSearchResult) {
      const jobTitle = q_text || 'Jobs';
      return `${jobTitle}${q_location ? ` in  ${q_location}` : ''}`;
    }
    return 'Jobs in United States';
  }, [isShowNoResults, isSearchResult, q_text, q_location]);

  return (
    <>
      <Search />
      {isShowNoResults && (
        <Typography.Title
          level={4}
          className='container py-5 mx-auto text-center'>
          No matching jobs found
        </Typography.Title>
      )}
      {isNoResults ? (
        <div className='h-[calc(100vh_-_161px)] flex flex-col justify-between'>
          <Typography.Title
            level={4}
            className='container py-5 mx-auto text-center'>
            No jobs available at this time
          </Typography.Title>
          <Navigation classes={{root: 'bg-white'}} />
        </div>
      ) : (
        <div className='flex container h-[calc(100vh_-_161px)] mx-auto shadow  rounded-lg overflow-hidden'>
          <Sidebar
            data={data}
            title={title}
            resultsCount={count}
            isLoading={isLoading || isSearchLoading}
            page={page}
            handleChangePage={handleChangePage}
          />
          <Content isListLoading={isLoading} />
        </div>
      )}
    </>
  );
};

export default Home;
