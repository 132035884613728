import AppCard from 'components/widgets/app-card';

import {getInputPlaceHolder} from 'utils/message-helper';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import AppInput from 'components/controles/input';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContactInfoSchema} from 'schemas/contact-info.schema';

const ContactDetailsForm = ({onContinue, details, setState}) => {
  const methods = useForm({
    resolver: yupResolver(ContactInfoSchema),
    defaultValues: details,
  });
  const {handleSubmit} = methods;
  const onSubmit = (formData) => {
    setState({contact_details: formData});
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <AppCard>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AppInput
            name='first_name'
            label='First Name'
            isRequired
            placeholder={getInputPlaceHolder('first name')}
          />
          <AppInput
            name='last_name'
            label='Last Name'
            isRequired
            placeholder={getInputPlaceHolder('last name')}
          />
          <AppInput
            name='title'
            label='Title'
            isRequired
            placeholder={getInputPlaceHolder('title')}
          />
          <AppInput
            name='email'
            label='Email Address'
            isRequired
            placeholder={getInputPlaceHolder('email address')}
          />
          <AppInput
            classes={{root: 'mb-5'}}
            name='phone_number'
            label='Phone Number'
            type='phone'
            placeholder={getInputPlaceHolder('phone number')}
          />
          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </form>
      </FormProvider>
    </AppCard>
  );
};

ContactDetailsForm.propTypes = {
  details: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default React.memo(ContactDetailsForm);
