export const INDUSTRIES = {
  accommodation_and_food_services: 'Accommodation and Food Services',
  administrative_and_support_services: 'Administrative and Support Services',
  agriculture_forestry_fishing_and_hunting:
    'Agriculture, Forestry, Fishing and Hunting',
  arts_entertainment_and_recreation: 'Arts, Entertainment, and Recreation',
  construction: 'Construction',
  educational_services: 'Educational Services',
  finance_and_insurance: 'Finance and Insurance',
  health_care_and_social_assistance: 'Health Care and Social Assistance',
  information: 'Information',
  management_of_companies_and_enterprises:
    'Management of Companies and Enterprises',
  manufacturing: 'Manufacturing',
  mining_quarrying_and_oil_and_gas_extraction:
    'Mining, Quarrying, and Oil and Gas Extraction',
  other_services_except_public_administration:
    'Other Services (except Public Administration)',
  professional_scientific_and_technical_services:
    'Professional, Scientific, and Technical Services',
  public_administration: 'Public Administration',
  real_estate_and_rental_and_leasing: 'Real Estate and Rental and Leasing',
  retail_trade: 'Retail Trade',
  transportation_and_warehousing: 'Transportation and Warehousing',
  utilities: 'Utilities',
  waste_management_and_remediation_services:
    'Waste Management and Remediation Services',
  wholesale_trade: 'Wholesale Trade',
};
