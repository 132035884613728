import {COUNTRIES} from '../constants/countries';
import {US_STATES} from '../constants/us-states';

class LocationHelper {
  static getCountries() {
    return Object.keys(COUNTRIES).map((countryKey) => {
      return {
        key: countryKey,
        value: countryKey,
        label: COUNTRIES[countryKey],
      };
    });
  }

  static getUsStates() {
    return Object.keys(US_STATES).map((stateKey) => {
      return {
        key: stateKey,
        value: stateKey,
        label: US_STATES[stateKey],
      };
    });
  }
}

export default LocationHelper;
