import {useQuery} from 'react-query';
import {instance} from '../api/axios';

export const useGetJob = (id) => {
  return useQuery(['job', id], async () => {
    const res = await instance.get(`/jobs/${id}`, {
      params: {expand: ['client'], include: ['skill_tags']},
    });
    return res.data;
  }, {enabled: !!id});
};
