export const COMPANY_SIZE = {
  '1_to_10': '1-10 Employees',
  '11_to_50': '11-50 Employees',
  '51_to_200': '51-200 Employees',
  '201_to_500': '201-500 Employees',
  '501_to_1000': '501-1000 Employees',
  '1001_to_5000': '1001-5000 Employees',
  '5001_to_10000': '5001-10,000 Employees',
  from_10000: '10,000+ Employees',
}
