import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiEditLine} from 'react-icons/ri';

const StepTitle = ({title, isEdit, onEditClick, ...restProps}) => {
  return (
    <div {...restProps} className='flex'>
      <Typography.Text className='font-semibold text-sm '>
        {title}
      </Typography.Text>
      {isEdit && (
        <Typography.Link className='ml-2' onClick={onEditClick}>
          <RiEditLine fontSize={16} />
        </Typography.Link>
      )}
    </div>
  );
};

StepTitle.propTypes = {
  title: PropTypes.string,
  isEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default StepTitle;
