import React from 'react';
import {DatePicker, Form} from 'antd';
import {Controller, useFormContext} from 'react-hook-form';

const AppDatePicker = ({
  label,
  name,
  isRequired,
  classes,
  labelPosition = 'top',
  ...rest
}) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
        <Form.Item
          label={label}
          layout='vertical'
          help={error ? error.message : ''}
          status={error ? 'error' : undefined}>
          <DatePicker
            {...rest}
            rootClassName={'max-w-[280px] w-full h-10 border-text-main'}
            status={error && 'error'}
            onBlur={onBlur}
            onChange={(dates) => onChange(dates)}
            value={value}
          />
        </Form.Item>
      )}
    />
  );
};

export default AppDatePicker;
