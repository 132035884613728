import 'react-phone-number-input/style.css';

import React from 'react';
import PhoneNumberInput from 'react-phone-number-input';
import cn from 'clsx';

const PhoneInput = ({className, ...props}) => {
  return (
    <PhoneNumberInput
      className={cn(
        '[&>input]:border-none [&>input]:placeholder:text-gray-500 [&>input]:outline-none focus:shadow-[0_0_0_2px_rgba(66,125,240,0.2)] hover:border-[#6fa3fc] relative w-full min-w-0 rounded-md px-[14px] py-[7px] text-[#494949] bg-white border border-[#d9d9d9]',
        props['aria-invalid'] === 'true' && 'border-error',
        className,
      )}
      defaultCountry={'US'}
      {...props}
    />
  );
};

export default PhoneInput;
