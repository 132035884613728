import React from 'react';
import cn from 'clsx';

const Label = ({text, isRequired, className}) => {
  return (
    <label className={cn('text-sm font-medium text-text-main', className)}>
      {isRequired && <span className='text-error mr-1'>*</span>}
      {text}
    </label>
  );
};

export default Label;
