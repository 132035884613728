import {object, string} from 'yup';
import {getRequiredMessage} from '../utils/message-helper';

export const primaryAddressSchema = object({
  country: string().required(getRequiredMessage('Country')),
  address_line_1: string().required(getRequiredMessage('Address')),
  address_line_2: string(),
  city: string().required(getRequiredMessage('City')),
  us_state: string().when('country', {
    is: (val) => val === 'united_states',
    then: (schema) => schema.required(getRequiredMessage('State')),
    otherwise: (schema) => schema.notRequired(),
  }),
  postal_code: string().required(getRequiredMessage('Postal / Zip Code')),
});
