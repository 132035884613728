import React from 'react';
import {Pagination, Skeleton, Typography} from 'antd';
import JobList from './job-list';
import Navigation from '../widgets/navigation';

const Sidebar = ({isLoading, title, resultsCount, page, handleChangePage, data}) => {
  if (isLoading) {
    return (
      <div className='w-1/3 shrink-0 p-4'>
        <Skeleton paragraph={{rows: 20}} />
      </div>
    );
  }

  return (
    <aside className='w-1/3 pb-10 overflow-auto shrink-0'>
      <div className='flex flex-col p-4 bg-primary'>
        <Typography.Title level={4} className='!text-white'>
          {title}
        </Typography.Title>
        <Typography.Text className='text-white'>
          {resultsCount} results
        </Typography.Text>
      </div>
      <JobList items={data} page={page} />
      <div className='p-4 border-b'>
        <Pagination
          onChange={handleChangePage}
          align='center'
          defaultCurrent={page}
          total={resultsCount}
        />
      </div>
      <Navigation />
    </aside>
  );
};

export default Sidebar;
