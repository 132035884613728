import React, {useEffect, useState} from 'react';
import {Button, Input} from 'antd';
import {CiLocationOn, CiSearch} from 'react-icons/ci';
import {useSearchParams} from 'react-router-dom';
const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const q_text = searchParams.get('q_text');
  const q_location = searchParams.get('q_location');
  const [text, setText] = useState(q_text || '');
  const [location, setLocation] = useState(q_location || '');

  const addQueryParam = () => {
    text ? searchParams.set('q_text', text) : searchParams.delete('q_text');
    location
      ? searchParams.set('q_location', location)
      : searchParams.delete('q_location');

    setSearchParams(searchParams);
  };

  useEffect(() => {}, []);

  const handleClear = (type) => () => {
    if (type === 'text') {
      searchParams.delete('q_text');
    } else {
      searchParams.delete('q_location');
    }
    setSearchParams(searchParams);
  };

  return (
    <div className='flex justify-center py-5 gap-4 mx-auto'>
      <Input
        allowClear
        onClear={handleClear('text')}
        value={text}
        onChange={(e) => setText(e.target.value)}
        size='large'
        placeholder='Title, skill or company'
        rootClassName={'max-w-[340px] w-full shrink-0'}
        prefix={<CiSearch />}
      />
      <Input
        allowClear
        onClear={handleClear('location')}
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        size='large'
        placeholder='City, state, or zip code'
        rootClassName='max-w-[340px] w-full shrink-0'
        prefix={<CiLocationOn />}
      />
      <Button onClick={addQueryParam} type='primary' size='large'>
        Search
      </Button>
    </div>
  );
};

export default Search;
