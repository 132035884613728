import React from 'react';
import {getInputPlaceHolder} from 'utils/message-helper';
import Editor from 'components/additional/editor';
import {Controller, useFormContext} from 'react-hook-form';
import Label from 'components/additional/label';

const AppEditor = ({label, name, isRequired}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <div className='flex flex-col relative pb-5'>
          {!!label && <Label className='mb-2' isRequired={isRequired} text={label} />}
          <Editor
            isError={!!fieldState.error}
            value={field.value}
            key={name}
            isCompanyProfile
            placeholder={getInputPlaceHolder('company description')}
            onChange={field.onChange}
          />
          {!!fieldState.error && (
            <span className='absolute left-0 bottom-0 text-error text-xs'>
              {fieldState.error.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default AppEditor;
