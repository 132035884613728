import AppCard from 'components/widgets/app-card';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import {COUNTRIES} from '../../../constants/countries';
import {US_STATES} from '../../../constants/us-states';

const PrimaryAddressView = ({details}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>Country</Typography.Text>
        <Typography.Paragraph>
          {COUNTRIES[details?.country]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>
          Address Line 1
        </Typography.Text>
        <Typography.Paragraph>{details?.address_line_1}</Typography.Paragraph>
      </div>
      {!!details?.address_line_2 && (
        <div className={'mb-5'}>
          <Typography.Text className='font-semibold'>
            Address Line 2
          </Typography.Text>
          <Typography.Paragraph>{details?.address_line_2}</Typography.Paragraph>
        </div>
      )}

      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>City</Typography.Text>
        <Typography.Paragraph>{details?.city}</Typography.Paragraph>
      </div>
      {details?.us_state && (
        <div className={'mb-5'}>
          <Typography.Text className='font-semibold'>
            State / Province
          </Typography.Text>
          <Typography.Paragraph>
            {COUNTRIES[details?.country] === COUNTRIES.united_states
              ? US_STATES[details?.us_state]
              : details?.us_state}
          </Typography.Paragraph>
        </div>
      )}

      <div className={'mb-5'}>
        <Typography.Text className='font-semibold'>
          Postal / Zip Code
        </Typography.Text>
        <Typography.Paragraph>{details?.postal_code}</Typography.Paragraph>
      </div>
    </AppCard>
  );
};

PrimaryAddressView.propTypes = {
  details: PropTypes.object,
};

export default PrimaryAddressView;
