import React from 'react';
import {Input, InputNumber} from 'antd';
import {Controller, useFormContext} from 'react-hook-form';
import cn from 'clsx';
import Label from '../additional/label';
import PhoneInput from '../additional/phone-input';

const AppInput = ({
  label,
  name,
  classes,
  type,
  extra,
  isRequired,
  labelPosition = 'top',
  ...props
}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <div
          className={cn(
            'flex',
            'max-w-[280px] w-full',
            labelPosition === 'top' && 'flex-col',
            labelPosition === 'left' && 'flex-row gap-x-2 max-w-full',
            classes?.root,
          )}>
          {!!label && (
            <Label
              text={label}
              isRequired={isRequired}
              className={cn(labelPosition === 'top' && 'mb-2', classes?.label)}
            />
          )}
          <div className='relative pb-5 w-full'>
            <div
              className={cn(
                'flex gap-2 items-center w-full',
                classes?.inputWrapper,
              )}>
              {type === 'number' ? (
                <InputNumber
                  status={fieldState.error && 'error'}
                  type={type}
                  {...props}
                  className={cn(
                    'h-10 py-1',
                    'border-text-main',
                    'placeholder:text-gray-500 w-full',
                    classes?.input,
                  )}
                  {...field}
                />
              ) : type === 'phone' ? (
                <PhoneInput
                  {...props}
                  className={cn(
                    'h-10',
                    'border-text-main',
                    fieldState.error && 'border-error',
                    classes?.input,
                  )}
                  {...field}
                />
              ) : (
                <Input
                  {...props}
                  status={fieldState.error && 'error'}
                  className={cn(
                    'h-10',
                    'border-text-main',
                    'placeholder:text-gray-500',
                    classes?.input,
                  )}
                  {...field}
                />
              )}
              {!!extra && <span className='text-text-main'>{extra}</span>}
            </div>
            {!!fieldState.error && (
              <span className='absolute left-0 bottom-0 text-error text-xs'>
                {fieldState.error.message}
              </span>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default AppInput;
