import React from 'react';
import {Select} from 'antd';
import {Controller, useFormContext} from 'react-hook-form';
import Label from '../additional/label';
import cn from 'clsx';

const AppSelect = ({label, name, classes, options, isRequired, ...props}) => {
  const {control} = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <div className='flex flex-col relative pb-5 max-w-[280px] w-auto'>
          <Label text={label} isRequired={isRequired} className='mb-2' />
          <Select
            {...field}
            status={fieldState.error && 'error'}
            className={cn('min-h-10', classes?.select)}
            {...props}
            options={options}
          />
          {!!fieldState.error && (
            <span className='absolute left-0 bottom-0 text-error text-xs'>
              {fieldState.error.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default AppSelect;
