import axios from 'axios';

const baseUrl =
  (process.env.MODE === 'development'
    ? process.env.BASE_API_DEV
    : process.env.REACT_APP_BASE_URL) || 'https://api.dev.sourcer.com/v1';

export const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});
