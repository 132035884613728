import './App.css';
import Home from './pages/home';
import Header from './components/widgets/header';
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Apply from './pages/apply';
import {QueryClientProvider, QueryClient} from 'react-query';
const queryClient = new QueryClient();
function App() {
  return (
    <>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Header />

          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/:id' element={<Home />} />
            <Route path='/apply/:id' element={<Apply />} />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
