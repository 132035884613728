import React from 'react';
import {Typography} from 'antd';
import PropTypes from 'prop-types';

const JobAbout = ({icon, company, industry, companySize, about}) => {
  return (
    <div>
      <Typography.Title level={4} className='!mb-4'>
        About Company
      </Typography.Title>
      <div className='flex items-center gap-5 mb-8'>
        <img className='h-10 w-10' src={icon} alt='company-logo' />
        <Typography.Text className='text-lg font-medium'>
          {company}
        </Typography.Text>
      </div>
      <Typography.Text className='flex items-center mb-5 gap-2'>
        <span>{industry}</span> | <span>{companySize}</span>
      </Typography.Text>
      <Typography.Paragraph>
        <div dangerouslySetInnerHTML={{__html: about}} />
      </Typography.Paragraph>
    </div>
  );
};

JobAbout.propTypes = {
  icon: PropTypes.string,
  company: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  companySize: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
};
export default JobAbout;
