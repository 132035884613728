export const JOB_STATUSES = {
  full_time: 'Full-time',
  part_time: 'Part-time',
  contract: 'Contract',
  temporary: 'Temporary',
};

export const CONTRACT_TYPES = {
  c2c: 'Corp to corp',
  independent: 'Independent',
  w2: 'W-2',
};
