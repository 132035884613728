import React from 'react';
import JobHeader from '../job/job-header';
import {WORKSITES} from '../../constants/work_sites';
import {CONTRACT_TYPES, JOB_STATUSES} from '../../constants/job-statuses';
import icon from '../../assets/images/u35.png';
import {Skeleton, Tag, Typography} from 'antd';
import JobAbout from '../job/job-about';
import {COMPANY_SIZE} from '../../constants/company-size';
import {INDUSTRIES} from '../../constants/industries';
import {useParams} from 'react-router-dom';
import {useGetJob} from '../../hooks/use-get-job';
import {getLocation} from '../../utils/get-location';
import {useSalary} from '../../hooks/use-salary';
import capitalize from 'antd/es/_util/capitalize';

const Content = ({isListLoading}) => {
  const {id} = useParams();
  const {data, isLoading} = useGetJob(id);

  const {isBill, salary} = useSalary(data);

  const jobStatus =
    data?.type === 'contract'
      ? `${JOB_STATUSES[data?.type]} (${CONTRACT_TYPES[data?.contract_type]})`
      : JOB_STATUSES[data?.type];

  if (isLoading || isListLoading || !data) {
    return (
      <div className='p-5 bg-primaryLight w-full'>
        <Skeleton avatar paragraph={{rows: 4}} />
      </div>
    );
  }

  return (
    <div className='p-5 bg-primaryLight w-full overflow-auto'>
      <JobHeader
        id={id}
        icon={data.client?.logo || icon}
        company={data.client?.name}
        location={getLocation(data)}
        jobTitle={data.job_title}
        createdAt={data.created_at}
        salary={salary}
        workSite={WORKSITES[data.job_worksite]}
        jobStatus={jobStatus}
        isBill={isBill}
        showButton
      />
      <div className='py-5'>
        {data.job_overview && (
          <>
            <Typography.Title level={4}>Overview</Typography.Title>
            <Typography.Text>
              <div dangerouslySetInnerHTML={{__html: data.job_overview}} />
            </Typography.Text>
          </>
        )}
        {data.job_role_description && (
          <>
            <Typography.Title level={4}>Role Description</Typography.Title>
            <Typography.Text>
              <div
                dangerouslySetInnerHTML={{__html: data.job_role_description}}
              />
            </Typography.Text>
          </>
        )}
        {data.job_responsibilities && (
          <>
            <Typography.Title level={4}>Responsibilities</Typography.Title>
            <Typography.Text>
              <div
                dangerouslySetInnerHTML={{__html: data.job_responsibilities}}
              />
            </Typography.Text>
          </>
        )}
        {data.job_skills_experience && (
          <>
            <Typography.Title level={4}>Skills/Experience</Typography.Title>
            <Typography.Text>
              <div
                dangerouslySetInnerHTML={{__html: data.job_skills_experience}}
              />
            </Typography.Text>
          </>
        )}
        {!!data.skill_tags?.data?.length && (
          <div className='flex items-center gap-2 mt-5 mb-10'>
            {data.skill_tags.data.map((el) => (
              <Tag key={el.id} color='#E8EEF6' className='border-none'>
                <Typography.Text>{el.tag.name}</Typography.Text>
              </Tag>
            ))}
          </div>
        )}
        {/*WORKSITE AND CRITERIA*/}
        <JobAbout
          icon={data.client?.logo || icon}
          company={data.client?.name}
          companySize={COMPANY_SIZE[data.client?.company_size]}
          industry={
            INDUSTRIES[data.client?.industry] ||
            capitalize(data.client?.industry.replaceAll('_', ' '))
          }
          about={data.client?.about}
        />
      </div>
    </div>
  );
};

export default Content;
