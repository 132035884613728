import React from 'react';
import logo from 'assets/images/logo.png';
import {Link} from 'react-router-dom';

const Header = () => {
  return (
    <header className='bg-white sticky top-0 z-10'>
      <div className='py-5 border-b container mx-auto'>
        <Link to='/'>
          <img src={logo} alt='sourcer' className='w-[120px]' />
        </Link>
      </div>
    </header>
  );
};

export default Header;
