import React, {useCallback, useState} from 'react';
import StepWizard from '../components/apply/steps/stepper';
import {getStepItems} from '../components/apply/steps/steps';
import JobHeader from '../components/job/job-header';
import icon from '../assets/images/u35.png';
import {WORKSITES} from '../constants/work_sites';
import {CONTRACT_TYPES, JOB_STATUSES} from '../constants/job-statuses';
import {Button, message, Skeleton, Typography} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetJob} from '../hooks/use-get-job';
import {useSalary} from '../hooks/use-salary';
import {getLocation} from '../utils/get-location';
import Navigation from '../components/widgets/navigation';
import {useUploadFile} from '../hooks/use-upload-file';
import {useApplyJob} from '../hooks/use-apply-job';

const Apply = () => {
  const {id} = useParams();
  const {data, isLoading} = useGetJob(id);
  const {isBill, salary} = useSalary(data);
  const uploadFile = useUploadFile(id);
  const applyJob = useApplyJob(id);
  const navigation = useNavigate();

  const isLoadingApply = uploadFile.isLoading || applyJob.isLoading;

  const jobStatus =
    data?.type === 'contract'
      ? `${JOB_STATUSES[data?.type]} (${CONTRACT_TYPES[data?.contract_type]})`
      : JOB_STATUSES[data?.type];

  const [state, setState] = useState(null);
  const handleSetState = useCallback((payload) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...payload,
      };
    });
  }, []);

  const [isApplied, setIsApplies] = useState(false);

  const handleFinish = () => {
    uploadFile.mutate(state.professional_experience.file.file, {
      onSuccess: ({key}) => {
        const {
          linkedin,
          years_of_experience,
          available_from,
          education,
          cover_letter,
        } = state.professional_experience;
        const payload = {
          resume_key: key,
          ...state.contact_details,
          phone_number: state.contact_details.phone_number?.replace('+', ''),
          ...state.primary_address,
          about: cover_letter,
          available_on: available_from,
          education_degree_level: education,
          years_experience: years_of_experience,
          linkedin,
        };
        applyJob.mutate(payload, {
          onSuccess: () => {
            setIsApplies(true);
          },
          onError: (error) => {
            message.error(error.response?.data.message || error.message);
          },
        });
      },
    });
  };

  if (isLoading) {
    return (
      <div className='p-5 bg-bodyBg w-full'>
        <Skeleton avatar paragraph={{rows: 4}} />
      </div>
    );
  }

  const renderStepper = () => (
    <div className='container mx-auto py-10'>
      <Typography.Title level={2} className='!mb-10'>
        Apply for this job
      </Typography.Title>
      <StepWizard
        bindProps={{state, setState: handleSetState}}
        items={getStepItems(handleFinish, isLoadingApply)}
        current={0}
        progressDot
        direction='vertical'
      />
    </div>
  );

  const renderSuccess = () => (
    <div className='container mx-auto py-10'>
      <Typography.Title level={2} className='!mb-10'>
        Application Submitted
      </Typography.Title>
      <Typography.Paragraph className='!mb-10'>
        Thank you for submitting your application for this role.
        <br /> <br />
        The company will review your application and, if you are a good match
        for this role, will reach out to you to engage in further conversation.
      </Typography.Paragraph>
      <Button type='primary' onClick={() => navigation('/')}>
        Back to Search
      </Button>
    </div>
  );

  return (
    <div className='bg-bodyBg'>
      <div className='bg-white  mb-5'>
        <div className='container mx-auto py-6'>
          <JobHeader
            id={id}
            icon={data.client?.logo || icon}
            company={data.client?.name}
            location={getLocation(data)}
            jobTitle={data.job_title}
            createdAt={data.created_at}
            salary={salary}
            workSite={WORKSITES[data.job_worksite]}
            jobStatus={jobStatus}
            isBill={isBill}
            classes={{
              root: 'border-none',
            }}
          />
        </div>
      </div>
      {isApplied ? renderSuccess() : renderStepper()}
      <Navigation classes={{root: 'bg-white'}} />
    </div>
  );
};

export default Apply;
