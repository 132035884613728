import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Typography} from 'antd';
import {CiClock1} from 'react-icons/ci';
import moment from 'moment';
import {BiSolidDollarCircle} from 'react-icons/bi';
import {BsFillBuildingsFill} from 'react-icons/bs';
import {TbCalendarTime} from 'react-icons/tb';
import {useNavigate} from 'react-router-dom';
import cn from 'clsx';

const JobHeader = ({
  icon,
  company,
  jobTitle,
  location,
  createdAt,
  salary,
  jobStatus,
  workSite,
  showButton,
  id,
  classes,
  isBill,
}) => {
  const navigate = useNavigate();

  const diff = useMemo(() => {
    if (createdAt) {
      const now = moment(new Date());
      const created = moment(new Date(createdAt * 1000));

      return now.diff(created, 'days');
    }
    return 0;
  }, [createdAt]);

  const onNavigate = () => {
    navigate(`/apply/${id}`);
  };

  return (
    <div className={cn('flex flex-col border-b pb-5', classes?.root)}>
      <div className='flex items-center gap-5 mb-8'>
        <img className='h-10 w-10' src={icon} alt='company-logo' />
        <Typography.Text className='text-lg font-medium'>
          {company}
        </Typography.Text>
      </div>
      <div className='flex justify-between'>
        <div>
          <Typography.Title level={4}>{jobTitle}</Typography.Title>
          <Typography.Text className='mb-2 inline-flex'>
            {location}
          </Typography.Text>
          <Typography.Text className='flex items-center gap-5'>
            <span className='inline-flex gap-2 items-center'>
              <CiClock1 /> {diff} days ago
            </span>
            <span className='inline-flex gap-2 items-center'>
              <BiSolidDollarCircle /> {salary}/ {isBill ? 'hour' : 'year'}
            </span>
            <span className='inline-flex gap-2 items-center'>
              <BsFillBuildingsFill /> {workSite}
            </span>
            <span className='inline-flex gap-2 items-center'>
              <TbCalendarTime /> {jobStatus}
            </span>
          </Typography.Text>
        </div>
        {showButton && (
          <Button
            size='large'
            className='min-w-[230px]'
            type='primary'
            onClick={onNavigate}>
            Apply
          </Button>
        )}
      </div>
    </div>
  );
};
JobHeader.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  company: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  createdAt: PropTypes.number,
  salary: PropTypes.string.isRequired,
  workSite: PropTypes.string.isRequired,
  jobStatus: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  type: PropTypes.string,
  isBill: PropTypes.bool,
  classes: PropTypes.objectOf({
    root: PropTypes.string,
  }),
};

export default JobHeader;
