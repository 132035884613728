import StepTitle from './step-title';
import ContactDetailsForm from '../forms/contact-details-form';
import ContactDetailsView from '../views/contact-details-view';
import PrimaryAddressView from '../views/primary-address-view';
import PrimaryAddressForm from '../forms/primary-address-form';
import ProfessionalExperienceForm from '../forms/professional-experience-form';
import ProfessionalExperienceView from '../views/professional-experience-view';
import CreateAccount from '../forms/create-account-form';

export const getStepItems = (handleOnFinish, isLoadingApply) => {
  return [
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 0}
          onEditClick={props.goToStep(0)}
          title='Contact Information'
          {...props}
        />
      ),
      subTitle: 'Provide your contact information',
      preview: ({state}) => (
        <ContactDetailsView details={state?.contact_details} />
      ),
      description: ({goToStep, state, setState}) => (
        <ContactDetailsForm
          onContinue={goToStep(1)}
          details={state?.contact_details}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 1}
          onEditClick={props.goToStep(1)}
          title={'Home Address'}
          {...props}
        />
      ),
      subTitle: 'Provide your current address',
      preview: ({state}) => (
        <PrimaryAddressView details={state?.primary_address} />
      ),
      description: ({goToStep, state, setState}) => (
        <PrimaryAddressForm
          onContinue={goToStep(2)}
          details={state?.primary_address}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 2}
          onEditClick={props.goToStep(2)}
          title={'Professional Experience'}
          {...props}
        />
      ),
      subTitle:
        'Provide your resume / cover letter and share your professional experience information',
      preview: ({state}) => (
        <ProfessionalExperienceView details={state?.professional_experience} />
      ),
      description: ({goToStep, state, setState}) => (
        <ProfessionalExperienceForm
          onContinue={goToStep(3)}
          details={state?.professional_experience}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => <StepTitle title='Apply' {...props} />,
      subTitle: 'Apply for this job',
      description: <CreateAccount onFinish={handleOnFinish} isLoading={isLoadingApply} />,
    },
  ];
};
